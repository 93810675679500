import React from 'react';
import PropTypes from 'prop-types';

import styles from './form.module.scss';

const InputCheckbox  = ({className, label, ...props}) => {
  const cls = styles.checkbox + (className ? ` ${className}` : '');
  return (
    <label className={cls}>
      <input type="checkbox" {...props} />
      <span>{label}</span>
    </label>
  );
};

InputCheckbox.defaultProps = {
  className: '',
  label: '',
};

InputCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

export default InputCheckbox;
