import React from 'react';
import PropTypes from 'prop-types';

import styles from './ModalError.module.scss';

const ModalError = ({error, dismiss}) => (
  error
    ? (
      <div className={styles.overlay} onClick={dismiss}>
        <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
          <p>{error}</p>
        </div>
      </div>
    )
    : null
);

ModalError.propTypes = {
  dismiss: PropTypes.func,
  error: PropTypes.string,
};

// TODO: Animate entrance.
// TODO: Add button to dismiss.

export default ModalError;
