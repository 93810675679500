import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';

import styles from './form.module.scss';

const Select = ({className, loaderColor, loading, options, placeholder, ...props}) => {
  let cls = styles.select + (className ? ` ${className}` : '');

  if (loading) {
    return (
      <span className={cls + ` ${styles.loading}`} style={props.style}>
        <span className={styles.spinner}>
          <Loader
            type="TailSpin"
            color={loaderColor}
            height="13"
            width="13"
          />
        </span>
        Carregando...
      </span>
    );
  }

  if (props.value === '') {
    cls += ` ${styles.unset}`;
  }

  return (
    <select className={cls} {...props}>
      <option className={styles.placeholder} disabled={true} value="">{placeholder}</option>
      {options.map((v, i) => (
        <option key={i} value={v}>{v}</option>
      ))}
    </select>
  );
};

Select.defaultProps = {
  className: '',
  loading: false,
  options: [],
  style: {},
  value: '',
};

Select.propTypes = {
  className: PropTypes.string,
  loaderColor: PropTypes.string,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
};

export default Select;
