import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ScrollableAnchor from "react-scrollable-anchor";

import FormSignature from "../components/FormSignature";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import SEO from "../components/SEO";

export default function render({ data }) {
  return (
    <Layout>
      <SEO title={data.site.siteMetadata.title} />

      <Hero
        background={data.hero.childImageSharp.fluid}
        channel={data.site.siteMetadata.channel}
      />

      <main className="main">
        <div className="wrap">
          <p>
            No domingo, 7 de abril, na Zona Norte do Rio de Janeiro, o carro de
            uma família negra, carioca, brasileira, foi fuzilado pelo Exército.
            Foram pelo menos 80 tiros de fuzil. Ocorreu em Guadalupe, bairro
            militar da Zona Norte do Rio de Janeiro. Evaldo dos Santos Rosa, o
            Manduca, ia com a família para um “chá de bebê” e foi fuzilado,
            morto, assassinado, com 80 tiros, na frente do filho de sete anos,
            da afilhada de 13, da sua esposa Luciana, e do sogro Sérgio. Mesmo
            baleado, manobrou o carro para proteger a família e evitou que
            também perdessem a vida. Sérgio, também atingido, sobreviveu. Um
            catador de papel que ajudou a família provavelmente não teve a mesma
            sorte e está desaparecido.
          </p>

          <p>
            O Exército Brasileiro, que é responsável por este assassinato
            brutal, emitiu nota justificando a barbárie, protegendo os militares
            que teriam apenas reagido a uma “injusta agressão”. Mais grave:
            acusaram Evaldo de ser um criminoso. Depois, diante da repercussão
            nas redes sociais e também na comunidade, tiveram que mudar o
            discurso, dizendo que havia um erro na atuação dos militares e,
            posteriormente, prendendo dez soldados. Na mesma região, dias antes,
            dois jovens – também negros – foram atacados pelo Exército e um
            deles morreu com um tiro nas costas. Em ambos os casos o Exército
            Brasileiro se achou no direito de julgar, condenar e executar
            cidadãos.
          </p>

          <p>
            Vivemos, no Rio de Janeiro e no Brasil, a perpetuação do genocídio
            da população negra, dos povos originários, dos pobres. A atuação
            racista do Estado Brasileiro e seu Exército é histórica e por isso
            estes fatos não são, de maneira alguma, um azar ou uma coincidência.
            O racismo é constitutivo do nosso Estado capitalista que ainda não
            superou relações historicamente coloniais de dominação. O racismo
            estrutural fica explícito com a ausência de políticas de direitos
            básicos e o aumento da militarização da vida na periferia. A
            militarização crescente da vida atinge a juventude periférica de
            modo amplo, até mesmo na captação para o próprio exército na medida
            em que este é visto como uma forma de ascensão social, onde os
            jovens são brutalizados, desumanizados, colocados perante um inimigo
            supostamente de mesma cor e mesma classe que a sua e, não raro,
            precisam de tratamento psicológico.
          </p>

          <p>
            Mulher negra, Marielle Franco também foi fuzilada, com 11 tiros.
            Mais de um ano depois, nenhuma resposta sobre quem mandou matá-la,
            apesar dos vários indícios de ligação do crime com as milícias e
            agentes políticos. Há três anos, cinco jovens que comemoravam o
            primeiro salário de um deles, também foram executados com 111 tiros.
            Há quase um ano o menino Marcus Vinicius, de apenas 14 anos, também
            foi morto, a caminho da escola na Favela da Maré, por um tiro que
            veio de um blindado da Polícia Militar. Recentemente, o menino de 12
            anos, Kauan Peixoto, foi morto pela PM com 3 tiros na Chatuba,
            quando estava indo comprar seu lanche. João Victor Dias Braga, de 22
            anos, foi morto na Taquara, ainda no início de abril, porque teve a
            sua furadeira confundida com uma arma pela polícia. Esses são apenas
            alguns exemplos que ocorrem todos os dias nas periferias e favelas
            do Brasil.
          </p>

          <p>
            Mesmo em países em guerra, cenas como essas não são comuns. É
            preciso uma nova política de segurança pública no Brasil. Ao invés
            de apoiar a população contra a matança generalizada de pretos,
            pobres e indígenas, Jair Bolsonaro e Wilson Witzel, Presidente da
            República e governador do Rio de Janeiro, respectivamente,
            incentivam a violência através da autorização para o abate nas
            favelas e justificam as barbáries que se avolumam numa espiral
            crescente de medo e derramamento de sangue inocente. Não podemos
            seguir aceitando essa forma de pensar o Brasil. O Governo Federal e
            o Estadual precisam se posicionar, exigindo que haja um julgamento
            civil, porque já está provado que o Exército está tentando abafar os
            crimes ocorridos. Apesar do fim oficial da intervenção militar
            federal, o exército segue cumprindo um papel policialesco no Rio de
            Janeiro, estimulado pelos governos. Exigimos a saída imediata do
            exército das ruas!
          </p>

          <p>
            - Precisamos que as cortes internacionais sejam acionadas e atuem!
          </p>

          <p>
            - Pedimos que os movimentos de direitos humanos pelo mundo
            denunciem!
          </p>

          <p>
            - Agentes de comunicação precisam fazer chegar em todos os cantos
            esta barbárie!
          </p>

          <p>
            - Por Marielle, Marcus Vinícius, Manduca e tantos outros. O mundo
            precisa ajudar a responder: “quantos mais tem que morrer para essa
            guerra acabar?”.
          </p>

          <p>
            <strong>Assinaturas iniciais:</strong>
          </p>

          <ul>
            <li>Rede Emancipa - Movimento Social de Educação Popular</li>
          </ul>

          <p>
            <small>
              Estamos recolhendo assinaturas de movimentos e parlamentares que
              em poucos dias serão adicionadas ao site. Se você quiser assinar
              enquanto movimento social ou parlamentar, fale conosco por meio do
              telefone (84) 9659-1792 (Tatiane).
            </small>
          </p>
        </div>
      </main>

      <ScrollableAnchor id="sign">
        <FormSignature channel={data.site.siteMetadata.channel} />
      </ScrollableAnchor>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        channel
      }
    }

    hero: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
