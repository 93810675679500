module.exports = [{
      plugin: require('/home/tiago/dev/emancipa/campaigns/80tiros/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-22599221-1"},
    },{
      plugin: require('/home/tiago/dev/emancipa/campaigns/80tiros/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('/home/tiago/dev/emancipa/campaigns/80tiros/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
