import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import Button from "./Button";
import CountSignatures from "./CountSignatures";

import styles from "./Hero.module.scss";

const Hero = ({ background, channel }) => (
  <div className={styles.container}>
    <Img
      className={styles.image}
      fluid={background}
      style={{ position: false }}
    />
    <div className={styles.overlay}>
      <div className="wrap">
        <h1 className={styles.title}>
          80 tiros do Exército:
          <br />
          <strong>É preciso parar</strong>
          <br />
          <span>a violência de estado</span>
        </h1>
        <p className={styles.description}>
          Leia e assine o manifesto de movimentos sociais, ativistas e cidadãos
          brasileiros contra o genocídio.
        </p>
        <CountSignatures channel={channel} />
        <p className={styles.description}>
          <Button to="#sign">Assine agora</Button>
        </p>
      </div>
    </div>
  </div>
);

Hero.propTypes = {
  background: PropTypes.object,
  channel: PropTypes.string
};

export default Hero;
